.signup {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  background-size: cover;
  background-position: center;
}

.sign-up-form {
  /* background-color: white; */
  padding: 0px 40px;
  /* border-radius: 10px; */
}

.signup-button {
  width: 100%;
  height: 50px;
  padding: 10px;
  border: none;
  /* background-color: rebeccapurple; */
  color: white;
  border-radius: 5px;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
  margin-top: 15px;
  margin-bottom: 5px;
}
