.customer-update-container {
  width: 70%;
  margin: 0 15%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.customer-update-container > div {
  padding: 3px;
}
.customer-update {
  margin: 5px 20%;
  width: 60%;
  padding: 15px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}
.search-customer-input {
  width: 30%;
}
.search-customer-input-container {
  display: flex;
  justify-content: center;
  padding: 5px;
  gap: 15px;
}
.customer-update-module-btns {
  border: 0;
  border-radius: 12px;
  height: 30px;
}
.customer-update-module-btns:hover {
  border-radius: 12px;
  height: 30px;
  box-shadow: 0px 4px 8px 0 rgb(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media (max-width: 992px) {
  .customer-update-container {
    width: 90%;
    margin: 0 5%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .customer-update {
    margin: 0 20%;
    width: 60%;
    padding: 15px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
  }
  .search-customer-input {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .customer-update-container {
    width: 100%;
    margin: 0%;
    padding: 5px;
    display: flex;
    flex-direction: column;
    box-shadow: inset 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
  }
  .customer-update {
    margin: 0 20%;
    width: 60%;
    padding: 15px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
  }
  .search-customer-input {
    width: 50%;
  }
}

.idli-dosa-update-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.idli-dosa-update-btn {
  padding: 5px 5px 5px 0;
}
.expire-and-update-date {
  width: 100%;
  display: flex;
  padding: 5px;
}
.expire_date {
  width: 50%;
  padding: 5px;
}
.update_date {
  width: 50%;
  padding: 5px;
  text-align: right;
}
.customer-update-btn {
  text-align: right;
}
.check-to-expire-date {
  zoom: 1.6;
}
.check-box-container {
  display: flex;
}
@media (max-width: 540px) {
  .customer-update {
    margin: 0 0%;
    width: 100%;
    padding: 15px !important;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;
  }
  .expire-and-update-date {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px;
  }
  .expire_date {
    width: 100%;
    padding: 5px;
  }
  .update_date {
    width: 100%;
    padding: 5px;
    text-align: start;
  }
  .search-customer-input-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 15px;
  }
  .search-customer-input {
    width: 80%;
  }
  .customer-update-module-btns {
    width: 60%;
    border: 0;
    border-radius: 12px;
    height: 30px;
  }
}
.list-group > li {
  width: 48%;
  margin: 5px 1%;
  border-radius: 5px !important;
}
