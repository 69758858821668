/* body {
  font-family: "Lato", sans-serif;
  /* transition: background-color 0.5s; */
/*  */
/* .logo {
  margin-top: -60px;
}
.logo img {
  width: 25%;
}
.companyname {
  color: #818181;
}

.sidenav {
  height: 100%;
  width: 15px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidenav label {
  padding: 125% 8px 8px 0;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  transition: margin-left 0.5s;
  padding: 16px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
} */

/* @media (max-width: 769px) {
  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-right: 15px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }
} */
.nav-toggle {
  display: none;
}
@media (max-width: 769px) {
  .nav-for-mobile {
    display: none;
  }
  .nav-toggle {
    display: block;
  }
  .toggleClose {
    display: none;
  }
}
