h1 {
  font-family: "Playfair Display", sans-serif !important;
}

form {
  border-radius: 10px;
}

body {
  background-color: #eeeeee !important;
}

.rs-sidenav-item,
.rs-sidenav {
  background-color: #c5d4b4 !important;
}
