.item-container {
  width: 100%;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-card {
  width: 30%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(209, 216, 240); */
  /* border: solid 3px rgb(0, 213, 255); */
  /* border-radius: 0 30px; */
}
.item-name-block {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.item-image-block {
  width: 80%;
  min-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.item-description-block {
  width: 80%;
  min-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.item-category-block {
  width: 80%;
  min-height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.items-label {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.item-input > input {
  /* background-color: rgb(209, 216, 240);
  border: solid 3px rgb(0, 213, 255); */
  border-radius: 6px;
}
.item-btns {
  width: 70%;
  height: 10%;
  display: flex;
  justify-content: center;
}
.item-btns > button {
  width: 80%;
  padding: 5% 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-top: 5px;
}
