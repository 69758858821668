.card-container {
  height: 12vh;
  width: 43vh;
  display: flex;
  border-radius: 5px;
}

@media (max-width: 577px) {
  .card-container {
    height: 10vh;
    width: 100%;
    display: flex;
    border-radius: 5px;
  }
}

.item-image-container {
  width: 45%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.item-image-container > img {
  border-radius: 5px;
  width: 80%;
  height: 100%;
  box-shadow: 2px 2px 2px 2px rgb(141, 133, 133);
}

.item-info {
  width: 65%;
  height: 100%;
}


.item-label {
  display: flex;
  width: 60%;
  height: 75%;
  justify-content: center;
  align-items: center;
}
.item-input {
  display: flex;
  width: 100%;
  height: 75%;
  justify-content: center;
  align-items: center;
}

.item-input > input {
  width: 95%;
  border: solid 2px rgb(176, 165, 165);
  border-radius: 6px;
}

.item-input-quantity {
  display: flex;
  width: 100%;
  height: 75%;
  margin-top: -25%;
  /* margin-left: -8%; */
  justify-content: center;
  align-items: center;
}

.item-input-quantity  > input {
  width: 95%;
  border: solid 2px rgb(176, 165, 165);
  border-radius: 6px;
}

.item-input-quantity input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.buttons {
  height: 4vh;
  display: flex;
  justify-content: center;
}

.ui-btn {
  border-radius: 15px;
  width: 15%;
  height: 100%;
  margin: 0 2px 0 0;
  cursor: pointer;
}

/* .ui-btn:hover {
  border-radius: 15px;
  width: 15%;
  height: 100%;
  cursor: pointer;
  color: white;
} */

.item-input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.select-item-category-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; 
  margin-bottom: 20px;
}

.category-input select {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

input[type="number"] {
  -moz-appearance: textfield;
}
