/*.login-container {
  width: 100%;
  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-card {
  width: 30%;
  height: 50%;
  display: flex;
  flex-direction: column;

  align-items: center;
  /* background-color: rgb(209, 216, 240);
  border: solid 3px rgb(0, 213, 255); */
/* border-radius: 0 30px;
} */
.login-phonenumber-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.login-password-block {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.login-label {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-input > input {
  /* background-color: rgb(209, 216, 240);
  border: solid 3px rgb(0, 213, 255); */
  border-radius: 6px;
}
.login-btns {
  width: 70%;
  height: 14%;
  display: flex;
  justify-content: center;
}
.login-btns > .btn {
  width: 80%;
  padding: 5% 0;
  display: flex;
  justify-content: center;
}
.btn > button {
  width: 70%;
  height: 4vh;
  border: solid 0px;
  border-radius: 25px;
  /* background-color: rgb(43, 189, 226); */
}
.btn > button:hover {
  width: 70%;
  height: 4vh;
  border: solid 0px;
  border-radius: 25px;
  /* background-color: rgb(6, 89, 110); */
}
.login-container {
  display: flex;
  justify-content: center;
}
.login-form {
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: center;
  border: solid 10px black;
  border-radius: 10px;
}
@media (max-width: 770px) {
  .login-form {
    width: 100%;
  }
}
.login-input {
  width: 100%;
  height: 45px;
  border: solid 0.5px black;
  border-radius: 10px;
}
.login-btn {
  width: 100%;
  border: solid 0.5px black;
  border-radius: 10px;
}
