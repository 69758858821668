.search-results-dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.search-results-dropdown label {
  margin-bottom: 20px;
  margin-right: 10px;
}

.search-results-dropdown select {
  width: 100%;
  max-width: 200px;
  margin-bottom: 20px;
}
