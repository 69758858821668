.add-customer-container {
  width: 60%;
}
.input-field {
  display: flex;
  justify-content: center;
}

.input-field > .formInput {
  width: 80%;
}

.formInput > input {
  width: 100%;
}
.start-end {
  padding-bottom: 10px;
}
